import { has } from "lodash";
import { Action, AnyAction, Reducer, applyMiddleware, compose, legacy_createStore as createReduxStore } from "redux";
import thunkMiddleware from "redux-thunk";

export type ActionHandler<TState, Action extends AnyAction> = (state: TState, action: Action) => TState

export const createStore = <State>(reducers: { [k: string]: Reducer<State> }, initialState: State = {} as State, instanceName: string = undefined) => {

    const keyValueDispatchReducer = (state: State = initialState, action: Action<string>): State => {

        if (has(reducers, action.type)) {

            return reducers[action.type](state, action);
        }

        return state;
    };

    let store: ReturnType<typeof createReduxStore> = null;

    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        store = createReduxStore(
            keyValueDispatchReducer,
            compose(applyMiddleware(thunkMiddleware), window.__REDUX_DEVTOOLS_EXTENSION__({
                name: instanceName || "Store"
            }))
        );
    }
    else {
        store = createReduxStore(
            keyValueDispatchReducer,
            compose(applyMiddleware(thunkMiddleware))
        );
    }

    return store;
};

import { cloneDeep } from "lodash";
import { LoadUnseenReportRequestCountAction, LoadUnseenReportRequestCountActionType } from "../Action/LoadUnseenReportRequestCount";
import { ReportsNavState } from "../ReportsNavState";

export default {
    [LoadUnseenReportRequestCountActionType]: (state: ReportsNavState, action: LoadUnseenReportRequestCountAction) => {
        const newState = cloneDeep(state);

        newState.data = { ...newState.data, ...action.data };

        return newState;
    }
};
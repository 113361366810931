import { thunkActionCreator } from "@src/components/ThunkActionCreator";
import { Action, Dispatch } from "redux";
import { OrganizationListItem } from "../../../../../sharedDomain/OrganizationListItem";
import { SiteHeaderState } from "../Store";

export const SetOrganizationsActionType = "set-organizations";

export interface SetOrganizationsAction extends Action {
    organizations: OrganizationListItem[];
}

export function getOrganizations(showDisabledOrganizations: boolean) {

    return async (dispatch: Dispatch<SetOrganizationsAction, SiteHeaderState>, getState: () => SiteHeaderState) => {

        const formData = new FormData();
        const state = getState();

        formData.append("__RequestVerificationToken", state.csrf);
        formData.append("showDisabledOrganizations", showDisabledOrganizations ? "true" : "false");

        const response = await fetch(
            state.uris.organizationSelection,
            {
                method: "POST",
                body: formData,
                credentials: "same-origin",
            }
        );

        if (response.status === 200) {

            const json = (await response.json()) as { organizations: OrganizationListItem[] };

            dispatch(thunkActionCreator((): SetOrganizationsAction => {
                return {
                    type: SetOrganizationsActionType,
                    organizations: json.organizations,
                };
            }));
        }
    };

}

import NotFoundComponent from "@src/pages/Error";
import { NotAuthorizedComponent } from "@src/pages/Error/NotAuthorized";
import "@src/themes/scss/error.scss";
import { AuthorizationError, NotFoundError } from "@src/utils/exceptions";
import React, { FunctionComponent } from "react";
interface ErrorProps {
    error?: Error
}

export const ErrorComponent: FunctionComponent<ErrorProps> = ({ error }) => {

    function refresh(): void {
        window.location.reload();
    }

    if (error && error instanceof NotFoundError)
        return <NotFoundComponent />;
    if (error && error instanceof AuthorizationError)
        return <NotAuthorizedComponent />;
    else
        return (<div className="error">

            <div className="container five-hundred">
                <img src={require("@src/themes/img/icons/circle-logo-icon.png")}
                    alt="Permission Click Icon"
                    title="Permission Click"
                    className="logo" />
                <div className="copy">
                    <h1>Unexpected Error</h1>
                    <p>Sorry, looks like there is a technical issue.</p>
                    <a href="#" className="btn" onClick={() => refresh()}>Try Again</a> <a className="btn" href="/">Home</a>
                </div>
            </div>
        </div>);
};
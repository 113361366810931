import { StyledReadOnlyBar } from "@src/components/layout/Header";
import * as React from "react";

interface Props {
    isPermissionClick: boolean;
}

export class ReadOnlyBar extends React.Component<Props, null> {

    public render() {

        return <StyledReadOnlyBar>
            <i className="fas fa-exclamation-triangle"></i>
            <span>Notice: Your profile is in Read Only mode. You are not allowed to make any changes. {!this.props.isPermissionClick ? "To disable Read Only mode, please contact your administrator." : ""}</span>
        </StyledReadOnlyBar>;
    }
}
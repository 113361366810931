type CheckCurrentUrlArgs = {
  currentUri: string;
  currentHash: string;
}

const checkCurrentUrl = ({ currentUri, currentHash }: CheckCurrentUrlArgs) => (linkUri: string) =>
  linkUri && linkUri.endsWith(currentUri.replace(currentHash, ''));

export { checkCurrentUrl };


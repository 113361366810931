import { cloneDeep } from "lodash";
import { Action } from "redux";
import { SiteHeaderState } from "../Store";

export interface SetAcceptedTermsAction extends Action {
    hasAccepted: boolean
}
export const SetAcceptedTermsActionType = "set-header-acceptedterms";

export default {

    [SetAcceptedTermsActionType]: (state: SiteHeaderState, action: SetAcceptedTermsAction) => {

        const newState: SiteHeaderState = cloneDeep(state);
        if (action.hasAccepted)
            newState.user.acceptedTermsDate = new Date().toDateString();

        return newState;
    },
};

import { Breakpoints } from "@src/customHooks";
import styled from "styled-components";
import { Flex } from "./Page";

const HeaderBar = styled.div`
  min-height: 90px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  pointer-events: none;
  & > * {
    pointer-events: auto; 
  }
`;

const HeaderBarContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  box-shadow: 4px -2px 10px -2px #000000;
  background-color: #fff;
  z-index: 999;
`;

const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  width: 100vw;
`;

const InfoBar = styled.div<{ screenSize: Breakpoints }>`
  width: 100%;
  height: 90%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 3px;

  > div {
    padding: 5px 5px 5px ${(props) => (props.screenSize === "desktop" ? `20px` : `10px`)};
    border-left: ${(props) => (props.screenSize === "desktop" ? `solid 1px #ddd` : `hidden`)};
  }
`;

const InfoBarContainer = styled(Flex) <{ screenSize: Breakpoints }>`\
  flex-direction: column;
  width: ${(props) => (props.screenSize === "desktop" ? `auto` : `100%`)};
`;

const NavBar = styled.div<{ screenSize: Breakpoints }>`
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.screenSize === "desktop" ? `left` : `space-between`)};
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-top: 20px;
  padding: 0;
  cursor: default;
`;

const SubMenuBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SubMenu = styled.ol`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  background: #fff;
  font-size: 14px;
  box-shadow: 0 0 8px 0 #000000;
  padding: 10px;
  border-radius: 3px;
  color: #3d3d3d;
  cursor: default;

  li {
    margin-bottom: 10px;
    padding-left: 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    i:first-of-type {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`;

const NavItem = styled.li<{ isCurrent: boolean; isEnabled: boolean }>`
  padding: 0 15px 5px;
  color: ${(props) => (props.isCurrent ? "#3260D3" : "#C4C4C4")};
  border-bottom: solid 2px ${(props) => (props.isCurrent ? "#3260D3" : "#fff")};
  font-weight: ${(props) => (props.isCurrent ? "500" : "300")};
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${(props) => (props.isEnabled ? "pointer" : "default")};

  &:first-of-type {
    padding-left: 0;
  }

  ol {
    display: block;
    position: absolute;
    top: 40px;
    left: 20px;
    z-index: 15;
  }

  &:hover {
    color: ${(props) => (props.isEnabled ? "#3260d3" : props.isCurrent ? "#3260D3" : "#C4C4C4")};

    ol {
      display: block;
    }
  }

  i:last-of-type {
    font-size: 20px;
    padding-left: 15px;
    text-decoration: none;
  }

  i:first-of-type {
    font-size: 5px;
    padding-right: 15px;
    padding-left: 0;
    text-decoration: none;
  }
`;

const TitleContainer = styled(Flex) <{ screenSize: Breakpoints }>`
  align-items: center;
`;

const StyledOrgName = styled.p<{ screenSize: Breakpoints }>`
  padding: ${(props) => (props.screenSize === "desktop" ? `0px 20px` : `0px 10px 0px 0px`)};
`;

const StyledLogo = styled.img<{ screenSize: Breakpoints }>`
  max-height: 120px;
  max-width: 120px;
  margin: ${(props) => (props.screenSize === "desktop" ? `0 20px` : `0px 10px 0px 0px`)};
  width: ${(props) => (props.screenSize === "desktop" ? `auto` : `60px`)};
`;

const StyledTitle = styled.label<{ screenSize: Breakpoints }>`
  display: block;
  font-weight: 500;
  font-size: ${(props) => (props.screenSize === "desktop" ? `22px` : `18px`)};
  border-left: ${(props) => (props.screenSize === "desktop" ? `hidden` : `solid 1px #ddd`)};
  padding-left: ${(props) => (props.screenSize === "desktop" ? `0px` : `10px`)};
  color: ${(props) => props.theme.colors.values.lightblack};
`;

const StyledReadOnlyBar = styled.div`
  background: #ffab00;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  gap: 5px;

  span {
    color: #4a4a4a;
  }
`;

export {
  HeaderBar,
  StyledOrgName,
  StyledLogo,
  StyledTitle,
  TitleContainer,
  HeaderBarContainer,
  InfoBar,
  InfoBarContainer,
  NavBar,
  NavList,
  NavItem,
  SubMenuBackground,
  SubMenu,
  Spacer,
  StyledReadOnlyBar
};

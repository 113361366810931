import * as React from "react";
import { connect } from "react-redux";
import { siteHeaderContext } from "../SiteHeader/Domain/Store";
import { State as AppState } from "./Domain/Store";
import { UpgradeModal } from "./UpgradeModal";

interface Props {
    isPermissionClick: boolean;
    headwayApiKey: string;
    isAdmin: boolean;
    subscriptionUri: string;
    logoUri: string;
}

interface State {
    showUpgradeModal: boolean;
    showLanguage: boolean;
    showError: boolean;
}

class PrivateFooterComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            showUpgradeModal: false,
            showLanguage: false,
            showError: false,
        };
    }

    public componentDidMount(): void {

        try {
            new google.translate.TranslateElement({ pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL }, 'google_translate_element');
        } catch (error) {
            console.error(error);
            this.setState({ showError: true });
        }

    }

    public render() {

        const languageClasses = ["language-wrapper"];

        if (this.state.showLanguage) {

            languageClasses.push("show");
        }

        return <footer className="page">
            <div className="layout-content">
                <div className={languageClasses.join(" ")}>
                    <div id="google_translate_element">
                        { this.state.showError && <p>An error occurred while loading translations. Please try to refresh the page to try again.</p>}
                    </div>
                </div>
                <a
                    id="Faq"
                    href="https://ps.powerschool-docs.com/permissionclick/latest/"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="btn"
                >
                    Tips &amp; FAQ
                </a>
                <a
                    className="menu-arrow"
                    id="language"
                    onClick={() => this.setState({ showLanguage: !this.state.showLanguage })}
                >
                    EN
                </a>
            </div>
            {
                this.state.showUpgradeModal
                    ? <UpgradeModal
                        onClose={() => this.closeUpgradeModal()}
                        isAdmin={this.props.isAdmin}
                        subscriptionManagementUri={this.props.subscriptionUri}
                        logoUri={this.props.logoUri}
                    />
                    : null
            }
        </footer>;
    }

    private showUpgradeModal() {
        this.setState({ showUpgradeModal: true });
    }

    private closeUpgradeModal() {
        this.setState({ showUpgradeModal: false });
    }
}

export const PrivateFooter = connect(
    (state: AppState): Partial<Props> => {

        return {
            isPermissionClick: state.isPermissionClick,
            headwayApiKey: state.headwayApiKey,
            isAdmin: state.isAdmin,
            subscriptionUri: state.subscriptionUri,
            logoUri: state.logoUri,
        };
    }, undefined, undefined, { context: siteHeaderContext }
)(PrivateFooterComponent);

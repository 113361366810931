import * as React from "react";

interface Props {

    organizationLogoUri: string;
    organizationName: string;
}

interface State {

}

export class OrganizationLogo extends React.Component<Props, State> {

    public render() {

        if(this.props.organizationLogoUri) {

                return <div className="logo-wrapper">
                    <img
                        src={this.props.organizationLogoUri}
                        alt={this.props.organizationName}
                        title={this.props.organizationName}
                    />
                </div>;
        }

        return <p className="logo-placeholder">
            {
                this.props.organizationName
                    ? <span>{this.props.organizationName.substring(0, 1)}</span>
                    : ""
            }
        </p>;
    }
}

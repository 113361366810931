import { thunkActionCreator } from "@src/components/ThunkActionCreator";
import { Action, Dispatch } from "redux";
import { ReportsNavState } from "../ReportsNavState";

export const LoadUnseenReportRequestCountActionType = "load-unseen-report-request-count";

export interface LoadUnseenReportRequestCountAction extends Action {
    data: ReportsNavState["data"];
}

export function loadUnseenReportRequestCount() {

    return async (dispatch: Dispatch<LoadUnseenReportRequestCountAction, ReportsNavState>, getState: () => ReportsNavState) => {

        const state = getState();

        const uri = state.uris?.loadNumUnseenReportRequests;

        if (uri) {
            const response = await fetch(uri, {
                method: "GET",
                credentials: "include",
            });

            if (response.status === 200) {

                const result = await response.json() as ReportsNavState["data"];

                dispatch(thunkActionCreator((): LoadUnseenReportRequestCountAction => {
                    return {
                        type: LoadUnseenReportRequestCountActionType,
                        data: result,
                    };
                }));
            }
        }
    };
}
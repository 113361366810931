import { cloneDeep } from "lodash";
import { SetErrorAction, SetErrorActionType } from "../Action/RunFirstLoad";
import { SiteHeaderState } from "../Store";


export default {

    [SetErrorActionType]: (state: SiteHeaderState, action: SetErrorAction) => {

        const newState: SiteHeaderState = {
            ...cloneDeep(state),
            error: action.error
        };

        return newState;
    },
};

import * as React from "react";

interface Props {

    logoUri: string;
}

export class Logo extends React.Component<Props, null> {

    public render() {

        return <img
            src={require("@src/themes/img/logo/logo_horizontal.svg")}
            alt="Permission Click"
            title="Permission Click"
            className="site-logo"
        />;
    }
}

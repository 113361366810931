import { thunkActionCreator } from "@src/components/ThunkActionCreator";
import { NotFoundError } from "@src/utils/exceptions";
import jwt from "@src/utils/jwt";
import { isValidGUID, isValidShardCode } from "@src/utils/regex";
import { Action, Dispatch } from "redux";
import { SiteHeaderState } from "../Store";

export const SetFirstLoadActionType = "set-header-first-load";

export interface SetFirstLoadAction extends Action {
    state: SiteHeaderState
}


export const SetErrorActionType = "set-header-error";

export interface SetErrorAction extends Action {
    error: Error
}

/**
 * 
 * @param shardCode A two-char country code or "int"
 * @param organizationId A valid guid
 */
export function RunFirstLoad(shardCode: string, organizationId: string) {
    return async (dispatch: Dispatch<SetFirstLoadAction, SiteHeaderState>, _getState: () => SiteHeaderState) => {

        async function getUserData(uri: URL) {
            const response = await fetch(uri, {
                method: "GET",
                credentials: "same-origin",
            });

            await response.ensureStatus();
            const json = (await response.json()) as SiteHeaderState;
            return json;
        }

        try {
            if (!isValidShardCode(shardCode))
                throw new NotFoundError();
            if (organizationId && !isValidGUID(organizationId))
                throw new NotFoundError();

            const uri = new URL(`${process.env.APP_API_URL}/${shardCode.toLowerCase()}/user`);
            if (organizationId)
                uri.searchParams.append("organizationId", organizationId);

            let json = await getUserData(uri);

            if (json.profilesWithAccessToCurrentPage?.length > 0) {
                const profileId = json.profilesWithAccessToCurrentPage[0].id;
                const currentProfileId = json.user.profileId;
                if (profileId !== currentProfileId) {
                    await jwt.getToken(true, profileId);
                    json = await getUserData(uri);
                }
            }

            dispatch(thunkActionCreator((): SetFirstLoadAction => {
                return {
                    type: SetFirstLoadActionType,
                    state: json
                };
            }));

        }
        catch (e) {
            dispatch(thunkActionCreator((): SetErrorAction => {
                return {
                    type: SetErrorActionType,
                    error: e as Error
                };
            }));

            throw e;
        }



    };
}

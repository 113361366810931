import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";


//
// ThunkActionCreator
//
// Instead of writing the same promise boilerplate over and over again to dispatch plain actions, this function
// will wrap an action factory for you.  Everything should be type safe so that the contract is easy to consume.


// // A thunking action creator has the option of reading the current state.
export interface ThunkingActionCreator<StateType, ActionType extends Action> extends ActionCreator<ActionType> {
    (getState: () => StateType): ActionType;
}

// Once thunked, the ActionCreator will be wrapped in a promise.
export interface ThunkedActionCreator<StateType, ActionType> extends ThunkAction<Promise<ActionType>, StateType, void, Action> {
    (dispatch: (action: ActionType) => ActionType, getState: () => StateType): Promise<ActionType>;
}

// Receives an action factory...
export function thunkActionCreator<StateType, ActionType extends Action>(createAction: ThunkingActionCreator<StateType, ActionType>): ThunkedActionCreator<StateType, ActionType> {
    // ...and returns a thunk...

    return (dispatch: (action: ActionType) => ActionType, getState: () => StateType): Promise<ActionType> =>
        // ...that returns a promise that completes after calling the action factory and dispatching its result.
        new Promise<ActionType>((resolve, reject) => {

            // The action factory can trigger failure by returning null or something that doesn't look like an action.
            const action = createAction(getState);

            if (!action || !action.type) {

                return reject(action);
            }

            return resolve(dispatch(action));
        });
}


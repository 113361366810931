
import { debounce } from "lodash";

const TokenRoute = "oauth/token";
const RefreshTokenRoute = "oauth/refresh-token";

function parseJwt(token: string): { [key: string]: string } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const inMemoryJWTManager = () => {
    let memory_token: string | null = null;
    let memory_expiresAt: Date | null = null;
    let refresh_token: string | null = null;
    const isExpired = () => (memory_token && memory_expiresAt && refresh_token && memory_expiresAt.getTime() < Date.now());

    const getTokenInternal = async (refresh = false, profileId: string) => {
        if (!hasValidToken() || refresh) {
            // The refresh token itself is on cookies

            if (!(process.env.AUTH_BASE_URL)) {
                throw new Error("Missing AUTH_URL environment variable!");
            }

            let url = process.env.AUTH_BASE_URL;

            url += (isExpired() || refresh) && refresh_token ? RefreshTokenRoute : TokenRoute;
            const content = new FormData();
            if ((isExpired() || refresh) && refresh_token)
                content.append("refresh_token", refresh_token ?? "");
            if (profileId)
                content.append("profileId", profileId);

            memory_token = null;
            memory_expiresAt = null;
            refresh_token = null;

            const response = await fetch(url, {
                method: "POST",
                credentials: "include",
                body: content,
                headers: {
                    "pragma": "no-cache",
                    "cache-control": "no-cache",
                }
            });

            if (response.status === 200) {
                const result = await response.json();
                memory_token = result["access_token"];
                memory_expiresAt = new Date(Date.parse(result["expires_at"]));
                refresh_token = result["refresh_token"];
            }
            else if (response.status >= 500) {
                await response.ensureStatus();
            }
        }

        return memory_token;
    };

    const getTokenDebounced = debounce((refresh: boolean, profileId: string) => getTokenInternal(refresh, profileId), 1000, { 'leading': true });

    const getToken = (refresh = false, profileId: string = undefined) => {
        if (refresh)
            getTokenDebounced.cancel();

        return getTokenDebounced(refresh, profileId);
    };

    const hasValidToken = () => memory_token && !isExpired();
    const getValidToken = () => !isExpired() && memory_token;

    const redirectToLogin = () => {
        if (!(process.env.AUTH_LOGIN_PAGE)) {
            throw new Error("Missing AUTH_LOGIN_PAGE environment variable!");
        }

        const uri = new URL(process.env.AUTH_LOGIN_PAGE)
        uri.searchParams.append("redirect_uri", window.location.href);
        window.location.href = uri.toString();
    };

    const getCountry = () => {
        if (memory_token)
            return parseJwt(memory_token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country"];
        else
            return undefined;
    }

    return {
        getToken, hasValidToken, redirectToLogin, getValidToken, getCountry
    }
};

export default inMemoryJWTManager();
import { Spinner } from "@src/components/atoms/Spinner";
import { LoadingMessage, LoadingModalBackground, LoadingModalContent } from "@src/components/NewStyles";
import React, { FC } from "react";

interface Props {
    message?: string
}

const FullScreenLoading: FC<Props> = ({ message }) => {
    return <>
        <LoadingModalContent>
            <Spinner />
            {message ? <LoadingMessage>{message}</LoadingMessage> : null}
        </LoadingModalContent>
        <LoadingModalBackground />
    </>;
};

export { FullScreenLoading };

import React from "react";
import { EscapeForRegex } from "./regex";


export function highLight(
    value: string,
    pattern: string
) {

    if (value
        && pattern) {
        const regex = new RegExp(EscapeForRegex(pattern), "gi");
        const splitText = value.split(regex);

        if (splitText.length <= 1) {

            return value;
        }

        const matches = value.match(regex);

        return splitText.reduce((arr, element, index) => (matches[index] ? [
            ...arr,
            element,
            <mark key={index}>
                {matches[index]}
            </mark>,
        ] : [...arr, element]), []);
    }

    return value;
}

const SearchResult = {
    highLight
};

export default SearchResult;
import * as React from "react";
import { Link } from "react-router-dom";
import { Logo } from "./Logo";
import { Mobile } from "./Mobile";
import { UserInfo } from "./UserInfo";


type SuperOrgHeaderProps = {
    homeUri: string;
    logoUri: string;
    dashboardUri: string;
    organizationName: string;
    userName: string;
    userRoleName: string;
    organizationLogoUri: string;
    email: string;
    logoutUri: string;
}

export const SuperOrgHeader = (props: SuperOrgHeaderProps) => (
    <nav className="super-org-header">
        <ul className="layout-content">

            <Mobile
                logoUri={props.logoUri}
                organizationLogoUri={props.organizationLogoUri}
                organizationName={props.organizationName}
                logoutUri={props.logoutUri}
                links={null}
            />
            <li className="logo">
                <Link className="nav-home" to={props.homeUri} title="Home">
                    <Logo
                        logoUri={props.logoUri}
                    />
                </Link>
            </li>

            <UserInfo
                organizationName={props.organizationName}
                userName={props.userName}
                userRoleName={props.userRoleName}
                organizationLogoUri={props.organizationLogoUri}
                showDistrictName={false}
                email={props.email}
                isPermissionClick={true}
                isDistrictAdministrator={false}
                dashboardUri={props.dashboardUri}
                editionName={null}
            />
        </ul>
    </nav>
)

import { theme as defaultTheme } from "@src/themes";
import { ButtonProps, ColorAlias, PaddingElement, RadiusKey, SpacingKey, StyledProps, TypographyElement } from "@src/themes/types";
import styled from "styled-components";



const getColor = (colorName: ColorAlias) => ({ theme }: StyledProps) =>
  theme.colors.value[theme.colors.alias[colorName]];

const getFontFamily =
  (elementName: TypographyElement) =>
    ({ theme }: StyledProps) =>
      theme.typography[elementName].fontFamily ||
      theme.typography.default.fontFamily;

const getFontWeight =
  (elementName: TypographyElement) =>
    ({ theme }: StyledProps) =>
      theme.typography[elementName].fontWeight ||
      theme.typography.default.fontWeight;

const getFontSize =
  (elementName: TypographyElement) =>
    ({ theme }: StyledProps) =>
      theme.typography[elementName].fontSize ||
      theme.typography.default.fontSize;

const getSpacing = (key: SpacingKey) => ({ theme }: StyledProps) =>
  theme.spacing[key];

const getPadding = (key: PaddingElement) => ({ theme }: StyledProps) =>
  theme.paddings[key];

const getRadius = (key: RadiusKey) => ({ theme }: StyledProps) => theme.radius[key];


export const StyledHeader = styled.h1`
  font-family: ${defaultTheme.typography.default.fontFamily};
  font-size: 1.5em;

  > i {
    margin-left: 15px;
    cursor: pointer;
  }
`;

export const StyledLabel = styled.label`
  font-family: ${defaultTheme.typography.default.fontFamily};
  color: ${defaultTheme.colors.value.black};
  margin-bottom: 10px;
  font-weight: 500;
  width: fit-content;
`;



export const LoadingModalBackground = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${getColor("backgroundLight")};
  opacity: 1;
  z-index: 999;
`;

export const LoadingModalContent = styled.div`
  position: fixed;
  z-index: 1000;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 300px;
`;

export const LoadingMessage = styled.div`
  margin: 30px auto 0;
  font-size: 14px;
  text-align: center;
`;

const PageHeader = styled.header`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${getColor("headerBorder")};
    padding: ${getPadding("pageHeader")};
    flex-wrap: wrap;
    margin-bottom: ${getSpacing("lg")};
`;

const ButtonRow = styled.div`
    ${PageHeader} & {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;


export const StyledButton = styled.button<ButtonProps>`
    font-family: ${getFontFamily("button")};
    font-size: ${getFontSize("button")};
    font-weight: ${getFontWeight("button")};
    background-color: ${(props) =>
    props.disabled
      ? getColor("disabledButtonBackground")(props)
      : props.backgroundColor
        ? getColor(props.backgroundColor)(props)
        : getColor("primary")(props)};
    color: ${(props) =>
    props.disabled
      ? getColor("disabledButtonText")(props)
      : props.color
        ? getColor(props.color)(props)
        : getColor("copyLight")(props)};
    padding: ${(props) => props.small
    ? getPadding("buttonSmall")(props)
    : getPadding("buttonLink")(props)};
    border: none;
    border-radius: ${(props) => props.small
    ? getRadius("small")(props)
    : getRadius("default")(props)};
    cursor: pointer;
    text-decoration: none;
    user-select: none;
    margin: 0 ${getSpacing("xs")};

    ${ButtonRow} &:last-child {
        margin-right: 0;
    }
`;

export const StyledOutlinedButton = styled(StyledButton) <{ color?: ColorAlias }>`
    background-color: transparent;
    border: 1px solid ${(props) => getColor(props.color || "outlinedButton")(props)};
    color: ${(props) => getColor(props.color || "outlinedButton")(props)};
`;
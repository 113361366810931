import { RoutePaths } from "@src/Routes";
import "@src/themes/scss/error.scss";
import React, { FunctionComponent, useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";


const NotFoundComponent: FunctionComponent = () => {

  const { shardCode, organizationId } = useParams();
  const navigate = useNavigate();
  const errorTitle = "Page Not Found";

  const title = `${errorTitle} : Permission Click`;
  useEffect(() => {
    if (title)
      document.title = title;
  }, [title]);


  function gotoForms(): void {
    if (shardCode && organizationId)
      navigate(generatePath(RoutePaths.OrganizationDashboard, { shardCode, organizationId }));
    else
      navigate(RoutePaths.Landing);
  }

  function gotoApprovals(): void {
    if (shardCode && organizationId)
      navigate(generatePath(RoutePaths.MyApprovals, { shardCode, organizationId }));
    else
      navigate(RoutePaths.Landing);
  }

  return (
    <div className="error">
      <div className="container">
        <img src={require("@src/themes/img/icons/circle-logo-icon.png")} alt="Permission Click Icon" title="Permission Click" className="logo" />
        <div className="copy">
          <h2>{errorTitle}</h2>
          <p>We&apos;re unable to find the page you were looking for. Check the address or use one of the following links to access your account.</p>
          <p>If a browser bookmark brought you to this page, make sure to update the bookmark&apos;s address once you&apos;ve returned to your account.</p>
          <a className="btn" onClick={gotoApprovals}>Go To Approvals</a>&nbsp;
          <a className="btn" onClick={gotoForms}>Go To Forms</a>

          <p>For additional assistance, please <a target="_blank" href="https://ps.powerschool-docs.com/permissionclick/latest/" rel="noreferrer noopener">visit our Help Center</a>.</p>
        </div>
      </div>
    </div>
  );
};

export { NotFoundComponent };

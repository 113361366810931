import { FilterHeader } from "@src/components/atoms/FilterHeader/FilterHeader";
import { HourGlass } from "@src/components/atoms/LoadingAnimation/HourGlass";
import { ToggleSlider } from "@src/components/atoms/ToggleSlider/ToggleSlider";
import { highLight } from "@src/utils/SearchResult";
import { EscapeForRegex } from "@src/utils/regex";
import { uniqBy } from "lodash";
import * as React from "react";
import { OrganizationListItem } from "../../../sharedDomain/OrganizationListItem";

interface Props {

    organizations: OrganizationListItem[];
    selectedOrganization?: OrganizationListItem;
    placeholder?: string;
    containsAllCountries: boolean;
    allowDisabled?: boolean;
    showDisabledOrganizations?: boolean;
    isPermissionClick?: boolean;

    onClick?(organization: OrganizationListItem): void;
    onShowDisabledChanged?(show: boolean): void;
}

interface State {

    showList: boolean;
    searchQuery: string;
    editionFilters: string[];
    showNotActive: boolean;
    selectedRow: number;
}

export class OrganizationSelect extends React.Component<Props, State> {

    private searchTimeout: number;
    private searchRef: HTMLInputElement;
    private scrollRefs: HTMLElement[] = [];

    public constructor(props: Props) {
        super(props);

        this.state = {
            showList: false,
            searchQuery: "",
            editionFilters: [],
            showNotActive: !props.isPermissionClick,
            selectedRow: -1,
        };
    }

    public render() {

        return <div className="district-select">
            <div
                className="select"
                onClick={(e) => { this.toggleList(); e.stopPropagation(); }}
            >
                {
                    this.props.selectedOrganization
                        ? <span
                            title={this.props.selectedOrganization.name}
                        >
                            {this.props.selectedOrganization.name}
                        </span>
                        : <span>{this.props.placeholder ? this.props.placeholder : "Select an Organization"}</span>
                }
            </div>

            {this.renderItems()}

        </div>;
    }

    private renderItems() {

        const filteredData = this.search();

        if (this.state.showList) {

            return <div>
                <div className="menu-background" onClick={() => this.setState({ showList: false })} />
                <div
                    className={`district-list ${this.props.containsAllCountries ? "pc-admin" : ""}`}
                >
                    <header>
                        <span>Select an Organization ({filteredData.length})</span>

                        {
                            this.props.isPermissionClick
                                && this.props.allowDisabled
                                ? <ToggleSlider
                                    label="Show Disabled"
                                    checked={this.props.showDisabledOrganizations}
                                    onClick={(checked) => this.props.onShowDisabledChanged(checked)}
                                />
                                : null
                        }

                        {
                            this.props.isPermissionClick
                                && this.props.allowDisabled
                                ? <ToggleSlider
                                    label="Show Not Active"
                                    checked={this.state.showNotActive}
                                    onClick={(checked) => this.setState({ showNotActive: checked })}
                                />
                                : null
                        }

                        <span className="search-container">

                            <input
                                ref={(search) => this.searchRef = search}
                                type="text"
                                className="search"
                                placeholder={this.props.placeholder}
                                aria-label={this.props.placeholder}
                                autoFocus={true}
                                onChange={(e) => this.searchChanged(e.target.value)}
                                onKeyDown={(e) => this.onKeyDown(e)}
                            />

                            <span className="clear-search"
                                onClick={() => this.searchCleared()}
                            >
                                <i className="far fa-times"></i>
                            </span>
                        </span>

                        <i
                            className="far fa-times close"
                            onClick={() => this.toggleList()}
                        >
                        </i>
                    </header>

                    <ul className="list-header">
                        <li className="district-name">Organization Name</li>

                        {
                            this.props.organizations
                                && this.props.organizations.some((x) => x.district)
                                ? <li className="district">District</li>
                                : null
                        }

                        <li className="edition">
                            <FilterHeader
                                label="Edition"
                                cssClasses={["edition"]}
                                allowSearch={false}
                                initialFilters={this.state.editionFilters}
                                items={
                                    this.props.organizations
                                        ? uniqBy(
                                            this.props.organizations.map((x) => ({ id: x.edition, label: x.edition })),
                                            "id"
                                        )
                                            .sort()
                                        : []
                                }
                                onDone={(filters) => this.editionFiltersSelected(filters)}
                                onCleared={() => this.setState({ editionFilters: [] })}
                            />
                        </li>
                        <li>Date Created</li>
                        <li>Last Active</li>
                        {
                            this.props.containsAllCountries
                                ? <li>Country</li>
                                : null
                        }
                    </ul>

                    {
                        filteredData
                            && filteredData.length > 0
                            ? this.listOrganizations(filteredData)
                            : this.props.organizations
                                && this.props.organizations.length > 0
                                ? <p>No organization found for this search</p>
                                : <HourGlass />
                    }
                </div>
            </div>;
        }
    }

    private listOrganizations(filteredData: OrganizationListItem[]) {

        return <div className="districts">
            {
                filteredData.map((organization: OrganizationListItem, index: number) => {

                    const classes = organization.enabled ? ["enabled"] : ["disabled"];

                    if (index === this.state.selectedRow) {
                        classes.push("selected");
                    }

                    return <ul
                        key={index}
                        onClick={() => this.onClick(organization)}
                        title={organization.name}
                        className={classes.join(" ")}
                        ref={(list) => this.scrollRefs[index] = list}
                    >
                        <li className="district-name">{highLight(organization.name, this.state.searchQuery)}</li>

                        {
                            this.props.organizations.some((x) => x.district)
                                ? <li className="district">{highLight(organization.district, this.state.searchQuery)}</li>
                                : null
                        }

                        <li className="edition">{highLight(organization.edition, this.state.searchQuery)}</li>
                        <li>{organization.createdOn}</li>
                        <li>{organization.accessedDate}</li>
                        {
                            this.props.containsAllCountries
                                ? <li>{organization.country?.toLocaleUpperCase()}</li>
                                : null
                        }
                    </ul>;
                })
            }
        </div>;
    }

    private toggleList() {

        const showList = !this.state.showList;
        let searchQuery = this.state.searchQuery;
        let editionFilters = this.state.editionFilters;

        if (!showList) {

            this.searchRef.value = "";
            searchQuery = "";
            editionFilters = [];
        }

        this.setState({
            showList,
            searchQuery,
            editionFilters,
        });
    }

    private onClick(organization: OrganizationListItem) {

        this.setState({
            showList: false,
        });

        if (this.props.onClick) {

            this.props.onClick(organization);
        }
    }

    private searchCleared() {

        this.searchRef.value = "";
        this.searchRef.focus();
        this.setState({ searchQuery: "" });
    }

    private searchChanged(query: string) {

        if (this.searchTimeout) {

            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = window.setTimeout(() =>
            this.setState({ searchQuery: query, selectedRow: 0 }),
            200
        );
    }

    private search() {

        let data = this.props.organizations
            ? this.state.showNotActive
                ? this.props.organizations
                : this.props.organizations.filter((x) => x.accessedDate)
            : [];

        if (!this.props.showDisabledOrganizations) {

            data = data.filter((x) => x.enabled);
        }

        if (this.state.editionFilters
            && this.state.editionFilters.length) {

            data = data.filter((x) => this.state.editionFilters.some((y) => y === x.edition));
        }

        if (this.state.searchQuery
            && this.state.searchQuery.length > 0) {

            const regex = new RegExp(EscapeForRegex(this.state.searchQuery), "gi");

            return data.some((x) => x.district)
                ? data.filter((x) => x.district.match(regex) || x.name.match(regex) || x.edition.match(regex))
                : data.filter((x) => x.name.match(regex) || x.edition.match(regex));
        }

        return data;
    }

    private editionFiltersSelected(editionFilters: string[]) {

        this.setState({
            editionFilters,
        });
    }

    private async onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {

        const pressedKey = event.key.toUpperCase();
        const filteredData = this.search();

        switch (pressedKey) {

            case "ENTER":
                if (this.props.onClick) {

                    if (filteredData.length > 0
                        && this.state.selectedRow >= 0
                        && this.state.selectedRow < filteredData.length) {

                        this.props.onClick(filteredData[this.state.selectedRow]);
                    }
                }
                break;

            case "ARROWUP":

                event.stopPropagation();
                event.preventDefault();

                if (this.state.selectedRow >= 1)
                    this.setState({ selectedRow: this.state.selectedRow - 1 }, () => {

                        this.scrollRefs[this.state.selectedRow].scrollIntoView({
                            inline: "nearest",
                            behavior: "smooth"
                        });
                    });

                break;

            case "ARROWDOWN":

                event.stopPropagation();
                event.preventDefault();

                if (this.state.selectedRow < filteredData.length - 1)
                    this.setState({ selectedRow: this.state.selectedRow + 1 }, () => {

                        this.scrollRefs[this.state.selectedRow].scrollIntoView({
                            inline: "nearest",
                            behavior: "smooth"
                        });
                    });
                break;
        }
    }
}

import chance from "chance";
import React from "react";

React.Component.prototype.setStateAsync = function (newState) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-this-alias
    const self: React.Component = this;

    return new Promise((resolve) => {
        self.setState(newState, () => {
            resolve()
        });
    });
};

Window.prototype.newGuid = function () {
    // https://caniuse.com/mdn-api_crypto_randomuuid
    return typeof crypto !== 'undefined' && crypto.randomUUID ? crypto.randomUUID() : chance().guid();
};
import { ReportRequest } from "@src/sharedDomain/ReportRequest";
import * as React from "react";

interface Props {
    numUnseenReportRequests?: number;
    reportRequest?: ReportRequest;
}

export class UnseenReportRequests extends React.Component<Props> {

    public constructor(props: Props) {

        super(props);
    }

    public render() {

        const showSymbol = this.props.reportRequest && this.props.reportRequest.unseen;

        let extraClasses = "";
        if (this.props.numUnseenReportRequests > 99) {
            extraClasses = " three-digit";
        }
        else if (this.props.numUnseenReportRequests > 9) {
            extraClasses = " two-digit";
        } else if (this.props.numUnseenReportRequests > 0 || showSymbol) {
            extraClasses = " one-digit";
        }

        const tooltip = "Unseen Report Request";

        return <div className={"unseen-report-requests" + extraClasses}
            title={!showSymbol && this.props.numUnseenReportRequests > 1 ? tooltip + "s" : tooltip} >
            {
                showSymbol
                    ? "!"
                    : this.props.numUnseenReportRequests > 0
                        ? this.props.numUnseenReportRequests
                        : ""
            }
        </div>
    }
}
import { isEmpty } from "lodash";
import * as React from "react";


interface Props {
    cssClasses?: string[];
}

export class HourGlass extends React.Component<Props> {

    public shouldComponentUpdate() {

        return false;
    }

    public render() {

        let classNames = ["loader"];

        if (!isEmpty(this.props.cssClasses)) {

            classNames = this.props.cssClasses;

            classNames.push("loader");
        }

        return <div
            className={classNames.join(" ")}
        >
        </div>;
    }
}

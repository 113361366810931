import { getAppInsights } from "@src/TelemetryProvider";
import { ArgumentNullError, AuthorizationError, NotFoundError, ValidationError } from "./exceptions";
import jwt from "./jwt";

const authenticatedDomains = process.env.AUTHENTICATED_HOSTNAMES.split(",");
const originalFetch = window.fetch;

window.fetch = async (input, init) => {
    if (!init) {
        init = {};
    }
    if (!init.headers) {
        init.headers = new Headers();
    }
    if (!input || input.toString().indexOf("null") === 0 || input.toString().indexOf("undefined") === 0)
        throw new ArgumentNullError("input");

    const url = input instanceof URL ? input : input instanceof Request ? new URL(input.url) : new URL(input.toString(), input.toString().startsWith("/") ? window.location.origin : undefined);
    const shouldAuthorize = authenticatedDomains.indexOf(url.hostname) > -1;

    if (shouldAuthorize) {
        try {
            const token = await jwt.getToken();

            if (!jwt.hasValidToken())
                jwt.redirectToLogin(); // Should we redirect to login in auth failures? 

            // init.headers could be: 
            //   `A Headers object, an object literal, 
            //    or an array of two-item arrays to set request’s headers.`
            if (init.headers instanceof Headers) {
                init.headers.append('Authorization', `Bearer ${token}`);
            } else if (init.headers instanceof Array) {
                init.headers.push(['Authorization', `Bearer ${token}`]);
            } else {
                // object ?
                init.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        catch (e) {
            if (e instanceof Error) {
                getAppInsights().trackException({
                    exception: e
                });
                jwt.redirectToLogin(); // Should we redirect to login in auth failures? 
            }
            else
                throw e;
        }
    }
    let result: Response;
    try {
        result = await originalFetch(input as Request, init);
    }
    catch (exception) {
        (exception as TypeError).message += ` url: ${input.toString()}`;
        throw exception;
    }
    await result.ensureStatus();
    return result;
};

window.Response.prototype.ensureStatus = async function (this: Response) {
    if (this.status === 404)
        throw new NotFoundError();
    if (this.status >= 401 && this.status <= 403) {
        const contentType = this.headers.get("content-type");
        const isJson = contentType && contentType === "application/json";

        const obj = isJson ? (await this.json()) as { url: string } : null;
        const text = !isJson ? { message: await this.text() } : null;
        if (obj?.url)
            window.location.href = obj.url;
        throw new AuthorizationError(obj || text || undefined);
    }
    if (this.status >= 400 && this.status < 500)
        throw new ValidationError(await this.json() || undefined);
    if (this.status < 200 || this.status >= 500)
        throw new Error(await this.text() || "Empty response");
}

export class ArgumentNullError extends Error {
    /**
     *
     */
    constructor(parameterName: string) {
        super(`Missing parameter '${parameterName}'.`);

    }
}

export class NotFoundError extends Error {
    /**
     *
     */
    constructor() {
        super(`Not found.`);

    }
}
export class ArgumentError extends Error {
    /**
     *
     */
    constructor(parameterName: string, message: string) {
        super(`${message} ('${parameterName}').`);

    }
}

export class ValidationError extends Error {
    constructor(data: { [key: string]: unknown }) {
        let title = data?.title as string || data?.message as string || "A validation error has ocurred";
        const errors = data?.errors as ValidationError["errors"];
        if (errors) {
            for (const key in errors) {
                title += `\r\n${key}: ${errors[key]}`;
            }
        }
        super(title);

        this.title = title;
        this.errors = errors;
        this.status = data?.status as number;

    }

    public readonly title: string;
    public readonly errors: { [key: string]: [string] };
    public readonly status: number;
}

export class AuthorizationError extends Error {
    constructor(data: { [key: string]: unknown }) {
        const title = data?.title as string || "An authorization failure has occured";
        super(title);

        this.title = title;
        this.status = data?.status as number;
    }

    public readonly title: string;
    public readonly status: number;
}
import * as React from "react";
import { Link } from "react-router-dom";
import { SubMenu } from "./SubMenu";
import { NavbarLink } from "./types";

interface Props {
    logoUri: string;
    organizationLogoUri: string;
    organizationName: string;
    logoutUri: string;
    links: NavbarLink[];
}

interface State {

    menuOpen: boolean;
}

export class Mobile extends React.Component<Props, State> {

    public constructor(props: Props) {

        super(props);

        this.state = {

            menuOpen: false,
        }
    }

    public render() {
        return <li className="mobile">

            <i
                className="far fa-bars"
                onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
            ></i>
            {
                this.state.menuOpen
                    ? <ul className="mobile-menu">
                        {this.renderMenu()}
                        <li>
                            <Link
                                className="sign-out"
                                to={this.props.logoutUri}
                            >Sign Out</Link>
                        </li>
                    </ul>
                    : null
            }
        </li>;
    }
    renderMenu() {
        return this.props.links && this.props.links.filter(({ enabled }) => enabled).map(link =>
            link.children && link.children.length
                ? <SubMenu label={link.label} links={link.children} key={link.label} onClick={() => this.setState({ menuOpen: false })} />
                : link.component ? link.component
                    : <li>
                        <Link onClick={() =>
                            this.setState({ menuOpen: false })
                        } className={`district-dashboard nav-link`} to={link.url}>{link.label}</Link>
                    </li>
        );

    }
}

export function EscapeForRegex(value: string) {

    return value.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}



const regex = /^[A-Fa-f\d]{8}-[A-Fa-f\d]{4}-[A-Fa-f0\d]{4}-[A-Fa-f\d]{4}-[A-Fa-f\d]{12}$/igm;
/**
 * Ensures the value is a valid GUID
 * @param value string value
 */
export function isValidGUID(value: string): boolean {

    if (value && value.length > 0) {
        const result = new RegExp(regex).test(value);
        return result;
    }

    return false;
}

export function isValidShardCode(value: string): boolean {
    if (value && (value.toLowerCase() === "int" || value.length === 2)) {
        return true;
    }

    return false;
}
import React, { Suspense } from "react";
import { Navigate, Outlet, Routes as ReactRoutes, Route, useParams } from "react-router-dom";
import { ProtectedAppPage } from "./components/layout/ProtectedAppPage";
import { FullScreenLoading } from "./pages/LandingPages/FullScreenLoading";

export interface PreviewProps {
  isPreview?: boolean;
}

// each page folder should be the equivalent of the legacy App Bundle (from Legacy App)
// the folder should have an index.tsx, creating a store, loading it, and importing the main <Component>
const NotFound = React.lazy(() => import(/* webpackChunkName: "NotFound" */'./pages/Error'));
const PublicLanding = React.lazy(() => import(/* webpackChunkName: "PublicLanding" */'./pages/LandingPages/PublicLanding'));
const PcAdminDashboard = React.lazy(() => import(/* webpackChunkName: "PcAdminDashboard" */'./pages/PcAdminDashboard'));
const OrganizationDashboard = React.lazy(() => import(/* webpackChunkName: "OrganizationDashboard" */'./pages/OrganizationDashboard'));
const EditOrganization = React.lazy(() => import(/* webpackChunkName: "EditOrganization" */'./pages/EditOrganization'));
const CreateOrganization = React.lazy(() => import(/* webpackChunkName: "CreateOrganization" */'./pages/CreateOrganization'));
const AcceptTerms = React.lazy(() => import(/* webpackChunkName: "AcceptTerms" */'./pages/AcceptTerms'));
const BulkUploadOrganizations = React.lazy(() => import(/* webpackChunkName: "BulkUploadOrganizations" */'./pages/BulkUploadOrganizations'));
const BulkUploadUsers = React.lazy(() => import(/* webpackChunkName: "BulkUploadUsers" */'./pages/BulkUploadUsers'));
const BankHook = React.lazy(() => import(/* webpackChunkName: "BankHook" */'./pages/BankHook'));
const UserManagement = React.lazy(() => import(/* webpackChunkName: "UserManagement" */'./pages/UserManagement'));
const IntegrationManagement = React.lazy(() => import(/* webpackChunkName: "IntegrationManagement" */'./pages/IntegrationManagement'));
const RosterManagement = React.lazy(() => import(/* webpackChunkName: "RosterManagement" */'./pages/RosterManagement'));
const MultiAccountMergeUsers = React.lazy(() => import(/* webpackChunkName: "MultiAccountMergeUsers" */'./pages/MultiAccountMergeUsers'));
const IntegratedDataManagement = React.lazy(() => import(/* webpackChunkName: "IntegratedDataManagement" */'./pages/IntegratedDataManagement'));
const CustomizeBranding = React.lazy(() => import(/* webpackChunkName: "CustomizeBranding" */'./pages/CustomizeBranding'));
const FeaturesManagement = React.lazy(() => import(/* webpackChunkName: "FeaturesManagement" */'./pages/FeaturesManagement'));
const Reports = React.lazy(() => import(/* webpackChunkName: "Reports" */'./pages/Reports'));
const ReportViewer = React.lazy(() => import(/* webpackChunkName: "Reports" */'./pages/Reports/ReportViewerBundle'));
const MergeOrganizations = React.lazy(() => import(/* webpackChunkName: "MergeOrganizations" */'./pages/MergeOrganizations'));
const InviteDistrictUsers = React.lazy(() => import(/* webpackChunkName: "InviteDistrictUsers" */'./pages/InviteDistrictUsers'));
const PcUserManagement = React.lazy(() => import(/* webpackChunkName: "PcUserManagement" */'./pages/PcUserManagement'));
const ChangeOrganizationType = React.lazy(() => import(/* webpackChunkName: "ChangeOrganizationType" */'./pages/ChangeOrganizationType'));
const AdManagement = React.lazy(() => import(/* webpackChunkName: "ActiveDirectoryManagment" */'./pages/ActiveDirectoryManagment'));
const UserSearch = React.lazy(() => import(/* webpackChunkName: "UserSearch" */'./pages/UserSearch'));
const FormCreator = React.lazy(() => import(/* webpackChunkName: "NewForm" */'./pages/FormCreator'));
const Templates = React.lazy(() => import(/* webpackChunkName: "Templates" */'./pages/Templates'));
const TemplateActivity = React.lazy(() => import(/* webpackChunkName: "TemplateActivity" */'./pages/TemplateActivity'));
const DocumentPreview = React.lazy(() => import(/* webpackChunkName: "DocumentPreview" */'./pages/DocumentPreview'));
const ProcessOverview = React.lazy(() => import(/* webpackChunkName: "ProcessOverview" */'./pages/ProcessOverview'));
const MyApprovals = React.lazy(() => import(/* webpackChunkName: "MyApprovals" */'./pages/MyApprovals'));
const DocumentApproval = React.lazy(() => import(/* webpackChunkName: "ApprovalWizard" */'./pages/ApprovalWizard'));


const RootPath = "/";
const OrgRootPath = `/:shardCode/:organizationId`;
const AdminRootPath = `/:shardCode`;
const RoutePaths = {
  Landing: RootPath,
  PermissionClickDashboard: "/permission-click-dashboard",
  BulkUploadOrganizations: `/bulk-upload-organizations`,
  MergeOrganizations: `${AdminRootPath}/merge-organizations`,
  InviteDistrictUsers: `${AdminRootPath}/invite-district-users`,
  ChangeOrganizationType: `${AdminRootPath}/change-organization-type`,
  UserSearch: `${AdminRootPath}/user-search`,
  BulkUploadUsers: `/bulk-upload-users`,
  AcceptTerms: "/accept-terms",
  FeaturesManagement: `${AdminRootPath}/features`,
  PcUserManagement: `/pc-user-management`,
  UserManagement: `${OrgRootPath}/user-management`,
  OrganizationDashboard: `${OrgRootPath}/dashboard`,
  Templates: `${OrgRootPath}/templates`,
  EditOrganization: `${OrgRootPath}/edit`,
  CreateOrganization: `${OrgRootPath}/create`,
  BankHook: `${OrgRootPath}/safepay`,
  IntegrationManagement: `${OrgRootPath}/integration-management`,
  RosterManagement: `${OrgRootPath}/roster-management`,
  IntegratedDataManagement: `${OrgRootPath}/integrated-data-management`,
  MultiAccountMergeUsers: `/multi-account-merge-users`,
  CustomizeBranding: `${OrgRootPath}/customize-branding`,
  Reports: `${OrgRootPath}/reports`,
  ReportViewer: `${OrgRootPath}/reports/:reportId/view`,
  AdManagement: `${OrgRootPath}/ad-management`,
  FormCreator: `${OrgRootPath}/new-form`,
  TemplateActivity: `${OrgRootPath}/templates/:templateId`,
  TemplatePreview: `${OrgRootPath}/templates/:templateId/preview`,
  ProcessOverview: `${OrgRootPath}/process-overview`,
  MyApprovals: `${OrgRootPath}/my-approvals`,
  DocumentApproval: `${OrgRootPath}/document-approval/:documentId`,
};

const GuidValidator = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

const ValidateOrganizationId = () => {
  const { organizationId } = useParams();
  const isValid = organizationId && organizationId.match(GuidValidator);
  return isValid ? <Outlet /> : <NotFound />;
};

const Router = (): JSX.Element => (
  <Suspense fallback={<FullScreenLoading />}>
    <ReactRoutes>
      <Route element={<ProtectedAppPage />}>
        <Route path={RoutePaths.PermissionClickDashboard} element={<PcAdminDashboard />} />
        <Route path={RoutePaths.AcceptTerms} element={<AcceptTerms />} />
        <Route path={RoutePaths.BulkUploadOrganizations} element={<BulkUploadOrganizations />} />
        <Route path={RoutePaths.BulkUploadUsers} element={<BulkUploadUsers />} />
        <Route path={RoutePaths.MultiAccountMergeUsers} element={<MultiAccountMergeUsers />} />
        <Route path={RoutePaths.FeaturesManagement} element={<FeaturesManagement />} />
        <Route path={RoutePaths.MergeOrganizations} element={<MergeOrganizations />} />
        <Route path={RoutePaths.InviteDistrictUsers} element={<InviteDistrictUsers />} />
        <Route path={RoutePaths.PcUserManagement} element={<PcUserManagement />} />
        <Route path={RoutePaths.ChangeOrganizationType} element={<ChangeOrganizationType />} />
        <Route path={RoutePaths.UserSearch} element={<UserSearch />} />
        <Route path={OrgRootPath} element={<ValidateOrganizationId />}>
          <Route path={RoutePaths.OrganizationDashboard} element={<OrganizationDashboard />} />
          <Route path={RoutePaths.EditOrganization} element={<EditOrganization />} />
          <Route path={RoutePaths.CreateOrganization} element={<CreateOrganization />} />
          <Route path={RoutePaths.BankHook} element={<BankHook />} />
          <Route path={RoutePaths.UserManagement} element={<UserManagement />} />
          <Route path={RoutePaths.IntegrationManagement} element={<IntegrationManagement />} />
          <Route path={RoutePaths.RosterManagement} element={<RosterManagement />} />
          <Route path={RoutePaths.IntegratedDataManagement} element={<IntegratedDataManagement />} />
          <Route path={RoutePaths.CustomizeBranding} element={<CustomizeBranding />} />
          <Route path={RoutePaths.ReportViewer} element={<ReportViewer />} />
          <Route path={RoutePaths.Reports} element={<Reports />} />
          <Route path={RoutePaths.AdManagement} element={<AdManagement />} />
          <Route path={RoutePaths.FormCreator} element={<FormCreator />} />
          <Route path={RoutePaths.TemplatePreview} element={<DocumentPreview />} />
          <Route path={RoutePaths.Templates} element={<Templates />} />
          <Route path={RoutePaths.TemplateActivity} element={<TemplateActivity />} />
          <Route path={RoutePaths.ProcessOverview} element={<ProcessOverview />} />
          <Route path={RoutePaths.MyApprovals} element={<MyApprovals />} />
          <Route path={RoutePaths.DocumentApproval} element={<DocumentApproval />} />

          {/* catch all under org */}
          <Route path="" element={<Navigate to={"dashboard"} />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route index path={RoutePaths.Landing} element={<PublicLanding />} />

        {/* catch all */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </ReactRoutes>
  </Suspense>
);

export {
  RoutePaths, Router
};


import { Theme } from "./types";

const theme: Theme = {
    colors: {
        value: {
            brandWhite: "#fff",
            brandBlue: "#0283ff",
            brandRed: "#f2594b",
            brandOrange: "#f4aa2d",
            brandGreen: "#8dc754",
            brandYellow: "#ef9400",

            blue100: "#e5f6fa",
            blue200: "#d6f2ff",
            blue300: "#0090d6",
            blue400: "#0283ff",
            blue500: "#006b9e",

            black: "#000",
            lightRed: "#f03742",
            red: "#ee0a13",
            utilityRed: "#d64f57",
            darkRed: "#bc2c35",
            green: "#75ca21",
            orange: "#ffa500",
            transparentGrey: "rgba(155, 155, 155, 0.8)",
            transparentBlue: "rgba(1, 51, 100, 0.1)",

            grey100: "#f9fafc",
            grey200: "#f9fafb",
            grey300: "#f8f8f8",
            grey400: "#eff2fb",
            grey500: "#eef0f3",
            grey600: "#eeeff0",
            grey700: "#ececec",
            grey800: "#e2e2e2",
            grey900: "#dde3e8",
            grey950: "#d6d6d6",
            grey1000: "#dee3e9",
            grey1100: "#dce3e9",
            grey1200: "#c4c4c4",
            grey1300: "#bcbcbc",
            grey1400: "#a4a4a4",
            grey1500: "#9b9b9b",
            grey1600: "#7b8292",
            grey1700: "#7a8292",
            grey1800: "#707070",
            grey1900: "#6a7080",
            grey2000: "#3d3d3d",
            grey2100: "#575e70",
            grey2200: "#4a4a4a",
            grey2300: "#1b2125",
            validationWarning: "#DDB527",
        },
        alias: {
            primary: "brandBlue",
            primaryDark: "blue500",
            brandRed: "brandRed",
            secondary: "brandRed",
            success: "brandGreen",
            error: "red",
            alert: "brandOrange",
            copyBlack: "black",
            copyGrey: "grey1800",
            copyDarkGrey: "grey2000",
            copyLight: "brandWhite",
            headerColor: "grey2000",
            bodyBackground: "brandWhite",
            brandIconGrey: "grey1300",
            backgroundLight: "grey1000",
            fieldBackground: "grey100",
            searchHighlight: "blue200",
            adminButton: "darkRed",
            iconWhite: "brandWhite",
            iconRed: "lightRed",
            iconGreen: "green",
            iconGrey: "grey1700",
            iconLightGrey: "grey1200",
            iconDarkGrey: "grey2200",
            inputBackground: "brandWhite",
            inputBorder: "grey900",
            inputColor: "grey2000",
            inputPlaceholderColor: "grey1400",
            inputDisabledBackground: "grey600",
            inputDisabledColor: "grey1800",
            builderBackgroundColor: "grey200",
            tableHeaderColor: "grey500",
            tableHeaderTextColor: "grey2300",
            tableRowHover: "grey200",
            tableRowSelected: "transparentBlue",
            modalCopy: "grey1900",
            modalFooter: "grey400",
            altButtonBackground: "grey1100",
            tableCopy: "grey1600",
            publicColor: "blue300",
            publishedColor: "brandGreen",
            unpublishedColor: "grey1500",
            headerBorder: "grey800",
            headerCallToAction: 'utilityRed',
            brandLightGrey: "grey700",
            modalBackground: "transparentGrey",
            outlinedButton: "grey1600",
            activeDashboardButton: "orange",
            disabledButtonBackground: "grey950",
            disabledButtonText: "grey1600",
            selectText: "grey1600",
            checkbox: "grey2100",
            approvalGreen: "green",
            approvalRed: "lightRed",
            approvalOrange: "orange",
            validationYellow: "validationWarning",
            highlightRed: "utilityRed",
            draft: "brandBlue",
            approved: "green",
            pendingapproval: "brandOrange",
            approvalpending: "brandOrange",
            rejected: "red",
            archived: "darkRed",
            closed: "grey1900",
            inprogress: "brandBlue",
            created: "grey2000",
            unpublished: "grey1900",
            published: "green",
            response: "blue500",
            form: "brandBlue",
            active: "green",
            deleted: "darkRed",
            on: "green",
            off: "brandOrange",
            invited: "brandBlue",
            pending: "brandOrange",
            complete: "green",
            expired: "darkRed",
            inactive: "red",
            incomplete: "brandOrange",
            school: "brandBlue",
            district: "blue500",
            notstarted: "grey1900",
            opened: "brandBlue",
            responded: "green",
            started: "brandBlue",
            cancelled: "darkRed",
            completed: "green",
        },
    },

    typography: {
        default: {
            fontFamily: "Roboto, sans-serif",
            fontWeight: "400",
            fontSize: "14px",
        },
        medium: {
            fontWeight: "500",
        },
        h1: {
            fontFamily: "Roboto, sans-serif",
            fontWeight: "400",
            fontSize: "24px",
        },
        button: {
            fontFamily: "Roboto, sans-serif",
            fontWeight: "400",
            fontSize: "14px",
        },
        headerIcon: {
            fontSize: '18px',
        },
        headerNotice: {
            fontSize: '12px',
        },
        tab: {
            fontSize: '14px',
            fontWeight: 'normal',
        },
        selectedTab: {
            fontSize: '15px',
            fontWeight: '500',
        },
        panelSubheader: {
            fontSize: '20px',
            fontWeight: '500',
        },
        small: {
            fontSize: '12px',
        },
    },

    spacing: {
        none: '0',
        xs: '5px',
        s: '10px',
        m: '15px',
        lg: '18px',
        xl: '30px',
    },

    paddings: {
        pageHeader: '30px 0 10px',
        buttonLink: '10px 18px',
        headerIcon: '10px 10px 5px',
        dropdownIcon: '0 0 0 30px',
        textInput: "10px 20px",
        buttonSmall: "4px 16px",
    },

    gradients: {
        default: "linear-gradient(0deg, #f0f2f5 0%, #fefefe 100%)",
        checkbox:
            "linear-gradient(0deg, rgb(244, 246, 247) 0%, rgb(250, 251, 251) 100%)",
    },

    radius: {
        default: "7px",
        small: "4px",
    },

    breakpoints: {
        s: {
            minWidth: "768px",
            contentWidth: "750px",
        },
        m: {
            minWidth: "992px",
            contentWidth: "970px",
        },
        lg: {
            minWidth: "1200px",
            contentWidth: "1170px",
        },
        xl: {
            minWidth: "1400px",
            contentWidth: "1260px",
        },
    },
};

export { theme };

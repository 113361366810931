import { getAppInsights } from "@src/TelemetryProvider";
import { cloneDeep } from "lodash";
import { SetFirstLoadAction, SetFirstLoadActionType } from "../Action/RunFirstLoad";
import { SiteHeaderState } from "../Store";


export default {

    [SetFirstLoadActionType]: (state: SiteHeaderState, action: SetFirstLoadAction) => {

        const newState: SiteHeaderState = {
            ...cloneDeep(state),
            ...action.state
        };
        const { user, organization: org } = newState;

        if (window.Appcues?.identify && user?.id && org?.id) {

            window.Appcues.identify(user.id, {
                first_name: user.firstName,
                last_name: user.lastName,
                email: newState.email,
                user_accepted_terms_date: user.acceptedTermsDate,
                is_organization_administrator: user.isOrganizationAdministrator,
                is_division_administrator: newState.isDistrictAdministrator,
                is_principal: user.isPrincipal,
                is_event_organizer: user.isEventOrganizer,
                organization_id: org.id,
                organization_profileId: user.profileId,
                organization_type: org.type,
                organization_countryCode: org.countryCode,
                organization_created_date: org.createdDate,
                organization_has_multiaccount: org.hasMultiAccount,
                organization_has_SafePayActivated: org.safePayActivated,
                organization_has_SafePay: org.hasSafePay
            });
        }

        const applicationInsights = getAppInsights();
        if (applicationInsights?.context && user?.id) {
            applicationInsights.setAuthenticatedUserContext(user.id, user.profileId, false);
        }

        return newState;

    },
};

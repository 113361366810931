import * as React from "react";


interface Props {

    checked: boolean;
    classNames?: string[];
    disabled?: boolean;
    label?: string | JSX.Element;
    title?: string;
    description?: string;
    labelAfter?: boolean;

    onClick?(checked: boolean): void;
}

export class ToggleSlider extends React.Component<Props> {

    public render() {

        const classes = this.props.classNames || [];

        classes.push("switch");

        if (this.props.disabled) {

            classes.push("disabled");
        }

        return <div
            className={"switch-container " + classes.join(" ")}
            onClick={(e) => !this.props.disabled ? this.onClick(e) : null}
            onKeyDown={(e) => !this.props.disabled ? (e.keyCode === 13 && this.onClick(e)) : null}
            tabIndex={0}
            title={this.props.title}
        >
            {
                this.props.label && !this.props.labelAfter
                    ? this.renderLabel()
                    : null
            }

            <div className={classes.join(" ")}>
                <i className={"far fa-toggle-on " + (this.props.checked ? "checked" : "fa-flip-horizontal")} />
            </div>

            {
                this.props.label && this.props.labelAfter
                    ? this.renderLabel()
                    : null
            }
        </div>;
    }

    private renderLabel() {
        return <>
            <label className={"switch-label" + (this.props.disabled ? " disabled" : "") + (this.props.labelAfter ? " after" : "")}>
                {this.props.label}
            </label>

            {
                this.props.description
                    ? <p className="option-description">{this.props.description}</p>
                    : null
            }
        </>;
    }

    private onClick(event: React.SyntheticEvent<HTMLDivElement>) {

        if (event.nativeEvent) {

            event.nativeEvent.stopImmediatePropagation();
        }

        if (event.stopPropagation) {

            event.stopPropagation();
        }

        if (this.props.onClick) {

            this.props.onClick(!this.props.checked);
        }
    }
}


interface FormToggleSliderProps {

    initialValue: boolean;
    classNames?: string[];
    disabled?: boolean;
    label?: string | JSX.Element;
    title?: string;
    description?: string;
    labelAfter?: boolean;

    name: string;
}

interface State {
    checked: boolean;
}

export class FormToggleSlider extends React.Component<FormToggleSliderProps, State> {

    state = { checked: this.props.initialValue } as State;

    public render() {

        const classes = this.props.classNames || [];

        classes.push("switch");

        if (this.props.disabled) {

            classes.push("disabled");
        }

        return <div
            className={"switch-container " + classes.join(" ")}
            onClick={(e) => !this.props.disabled ? this.onClick(e) : null}
            onKeyDown={(e) => !this.props.disabled ? (e.keyCode === 13 && this.onClick(e)) : null}
            tabIndex={0}
            title={this.props.title}
        >
            {
                this.props.label && !this.props.labelAfter
                    ? this.renderLabel()
                    : null
            }

            <div className={classes.join(" ")}>
                <input type="hidden" name={this.props.name} value={this.state.checked ? "true" : "false"} />
                <i className={"far fa-toggle-on " + (this.state.checked ? "checked" : "fa-flip-horizontal")} />
            </div>

            {
                this.props.label && this.props.labelAfter
                    ? this.renderLabel()
                    : null
            }
        </div>;
    }

    private renderLabel() {
        return <>
            <label className={"switch-label" + (this.props.disabled ? " disabled" : "") + (this.props.labelAfter ? " after" : "")}>
                {this.props.label}
            </label>

            {
                this.props.description
                    ? <p className="option-description">{this.props.description}</p>
                    : null
            }
        </>;
    }

    private onClick(event: React.SyntheticEvent<HTMLDivElement>) {

        if (event.nativeEvent) {

            event.nativeEvent.stopImmediatePropagation();
        }

        if (event.stopPropagation) {

            event.stopPropagation();
        }

        this.setState({ checked: !this.state.checked });
    }
}

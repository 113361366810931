import { ChangeOrganizationAction, ChangeOrganizationActionType } from "../Action/ChangeOrganization";
import { SiteHeaderState } from "../Store";


export default {

    [ChangeOrganizationActionType]: (_state: SiteHeaderState, _action: ChangeOrganizationAction) => {
        // reset state
        const newState = {
        } as SiteHeaderState;

        return newState;

    },
};

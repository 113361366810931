
export enum JobStatus {
    Running = 0,
    Pending = 1,
    Failed = 2,
    Succeded = 3,
    PartiallySucceeded = 4,
}
export interface BackgroundJobNotification {
    id: string;
    description: string;
    completed: Date;
    status: JobStatus;
    profile: { id: string, name: string };
    document: { id: string, name: string };
    started: Date;
    requestedBySystemUser: boolean;
    ownedByUser: boolean;
}

import * as React from "react";

interface Props {
    onClose(): void;
    subscriptionManagementUri: string;
    isAdmin: boolean;
    logoUri: string;
}

export class UpgradeModal extends React.PureComponent<Props> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {

        return <div className="upgrade-modal">
            <div className="modal-background" onClick={() => this.props.onClose()}></div>
            <div className="upgrade-modal-content">
                <header>
                    <img src={"@src/themes/img/logo/logo_horizontal.svg"} alt="logo" />
                    <i className="fal fa-times" onClick={() => this.props.onClose()}></i>
                </header>

                <div className="upgrade-modal-body">
                    {
                        this.props.isAdmin
                            ? <a href={this.props.subscriptionManagementUri} target="_blank" rel="noreferrer noopener">Upgrade your account to unlock enhanced support like Live Chat and other premium features.</a>
                            : <p>Contact your administrator to upgrade your account to unlock enhanced support like Live Chat and other premium features.</p>
                    }
                </div>
            </div>
        </div>;
    }
}

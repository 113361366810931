import { thunkActionCreator } from "@src/components/ThunkActionCreator";
import { ArgumentNullError } from "@src/utils/exceptions";
import { NavigateFunction } from "react-router-dom";
import { Action, Dispatch } from "redux";
import { SiteHeaderState } from "../Store";

export const ChangeOrganizationActionType = "set-header-organization";

export interface ChangeOrganizationAction extends Action {
    shardCode: string;
    organizationId: string;
}

export function changeOrganization(shardCode: string, organizationId: string, navigate: NavigateFunction, url: string) {
    return async (dispatch: Dispatch<ChangeOrganizationAction, SiteHeaderState>, _getState: () => SiteHeaderState) => {
        if (!shardCode)
            throw new ArgumentNullError("shardCode");


        await dispatch(thunkActionCreator((): ChangeOrganizationAction => {
            return {
                type: ChangeOrganizationActionType,
                organizationId: organizationId,
                shardCode: shardCode
            };
        }));

        navigate(url);
    };
}

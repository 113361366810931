import { WithRouterProps, withRouter } from "@src/utils/withRouter";
import * as React from "react";
import { connect } from "react-redux";
import { OrganizationListItem } from "../../../../sharedDomain/OrganizationListItem";
import { Dispatchers } from "../../../Dispatchers";
import { OrganizationSelect } from "../../OrganizationSelect/OrganizationSelect";
import { changeOrganization } from "../Domain/Action/ChangeOrganization";
import { getOrganizations } from "../Domain/Action/GetOrganizations";
import { ProfileListItem } from "../Domain/ProfileListItem";
import { SiteHeaderState, siteHeaderContext } from "../Domain/Store";
import { Profile } from "./Profile";

const dispatchers = {
    getOrganizations,
    changeOrganization
};
interface Props extends WithRouterProps {

    isPermissionClick: boolean;
    isDistrictAdministrator: boolean;
    dashboardUri: string;
    logoutUri: string;
    profiles: ProfileListItem[];
    organizations: OrganizationListItem[];

    onCancel(): void;
}

interface State {
    showDisabled: boolean;
    runAnimation: boolean;
}

class ProfileSelectionComponent extends React.Component<Props & Dispatchers<typeof dispatchers>, State> {

    state = {} as State;

    public componentDidMount() {
        this.props.getOrganizations(false);
        window.setTimeout(() => this.setState({ runAnimation: true }), 30);
        window.setTimeout(() => this.setState({ runAnimation: false }), 500);
    }

    public render() {

        return <nav>
            <div className="menu-background" onClick={() => this.props.onCancel()} />
            <div
                id="header-profile-selection"
                className={`profile-selection ${this.state.runAnimation === undefined ? "animation-closed" : this.state.runAnimation === true ? "animation" : ""}`}
                onClick={(e) => e.stopPropagation()}
            >
                {this.renderProfiles()}
                <div className="buttons">
                    {
                        (this.props.isPermissionClick
                            || this.props.isDistrictAdministrator)
                            ? <OrganizationSelect
                                placeholder="Search Accounts"
                                allowDisabled={this.props.isPermissionClick}
                                showDisabledOrganizations={this.state.showDisabled}
                                onShowDisabledChanged={() => this.onShowDisabledChanged()}
                                organizations={this.props.organizations}
                                onClick={(org) => this.goNavigate(org)}
                                containsAllCountries={this.props.isPermissionClick}
                                isPermissionClick={this.props.isPermissionClick}
                            />
                            : null
                    }

                    <a
                        className="btn sign-out"
                        href={this.props.logoutUri}
                    >Sign Out</a>
                </div>
            </div>
        </nav>;
    }

    async goNavigate(organization: OrganizationListItem) {
        const url = this.props.organizations.find((x) => x.id === organization.id).url;
        await this.props.changeOrganization(organization.country, organization.id, this.props.navigate, url);
        this.props.onCancel();
    }

    private renderProfiles() {

        if (this.props.profiles
            && this.props.profiles.length > 0) {


            return <div className="list">
                {
                    this.props.profiles.map((profile) => {

                        return <Profile
                            key={profile.id}
                            profile={profile}
                        />;
                    })
                }
            </div>;
        }
    }

    private onShowDisabledChanged() {

        const show = !this.state.showDisabled;

        if (show
            && !this.props.organizations.some((x) => !x.enabled)) {

            this.props.getOrganizations(show);
        }

        if (!this.props.organizations) {

            this.props.getOrganizations(show);
        }

        this.setState({ showDisabled: show });
    }
}

export const ProfileSelection = withRouter(connect(
    (state: SiteHeaderState) => ({
        organizations: state.organizations,
        profiles: state.profiles,
        dashboardUri: state.uris.dashboard,
        logoutUri: state.uris.logout
    }),
    dispatchers, undefined, { context: siteHeaderContext }
)(ProfileSelectionComponent));

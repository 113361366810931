import { RoutePaths } from "@src/Routes";
import "@src/themes/scss/error.scss";
import React, { FunctionComponent, useEffect } from "react";


const NotAuthorizedComponent: FunctionComponent = () => {
    const errorTitle = "Not Authorized";

    const title = `${errorTitle} : Permission Click`;
    useEffect(() => {
        if (title)
            document.title = title;
    }, [title]);


    return (
        <div className="error">
            <div className="container">
                <img src={require("@src/themes/img/icons/circle-logo-icon.png")} alt="Permission Click Icon" title="Permission Click" className="logo" />
                <div className="copy">
                    <h2>{errorTitle}</h2>
                    <p>It seems you are not authorized to view the page you were looking for. Check the address or use the following link to access your account.</p>
                    <a className="btn" href={RoutePaths.Landing}>Start over</a>

                    <p>For additional assistance, please <a target="_blank" href="https://ps.powerschool-docs.com/permissionclick/latest/" rel="noreferrer noopener">visit our Help Center</a>.</p>
                </div>
            </div>
        </div>
    );
};

export { NotAuthorizedComponent };

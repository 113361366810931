import { RoutePaths } from "@src/Routes";
import { WithRouterProps, withRouter } from "@src/utils/withRouter";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatchers } from "../../../Dispatchers";
import { siteHeaderContext } from "../Domain/Store";
import { Notifications } from "./Notifications";
import { OrganizationLogo } from "./OrganizationLogo";
import { ProfileSelection } from "./ProfileSelection";

const dispatchers = {

};


interface Props extends WithRouterProps {

    showDistrictName: boolean;
    districtName?: string;
    organizationName: string;
    userName: string;
    userRoleName: string;
    organizationLogoUri: string;
    email: string;
    isPermissionClick: boolean;
    isDistrictAdministrator: boolean;
    dashboardUri: string;
    editionName: string;
}

interface State {
    showProfileSelection: boolean;
}

class UserInfoComponent extends React.Component<Props & Dispatchers<typeof dispatchers>, State> {

    state = {} as State;

    public render() {

        const userName = `${this.props.userName} – ${this.props.userRoleName}`;
        const userTitleText = `${this.props.userName} – ${this.props.userRoleName}: ${this.props.email}`;
        const organizationNameCopy = this.props.editionName
            ? `${this.props.organizationName} – ${this.props.editionName} Edition`
            : this.props.organizationName;


        const classNames = ["user-info"];

        if (this.props.isPermissionClick
            || this.props.isDistrictAdministrator) {

            classNames.push("dashboard");
        }

        return <li className={classNames.join(" ")}>
            <div className="current-user" onClick={() => this.toggleProfileSelection()}>
                <div className={`org-name ${(!this.props.showDistrictName || !this.props.districtName ? "district" : "")}`}>
                    <p title={organizationNameCopy}>{organizationNameCopy}</p>
                    {
                        this.props.showDistrictName
                            && this.props.districtName
                            ? <p title={this.props.districtName}>{this.props.districtName}</p>
                            : null
                    }

                    <p title={userTitleText}>{userName}</p>
                </div>
                <OrganizationLogo
                    organizationLogoUri={this.props.organizationLogoUri}
                    organizationName={this.props.organizationName}
                />
                {this.renderProfileSelection()}
            </div>
            {
                this.props.isPermissionClick
                    || this.props.isDistrictAdministrator
                    ? <i
                        title="Back to your dashboard"
                        className="fas fa-home-alt back-to-dashboard"
                        onClick={(event) => this.dashboardClicked(event)}
                    >
                    </i>
                    : null
            }
            {<Notifications />}
        </li>;
    }

    private dashboardClicked(event: React.SyntheticEvent) {
        event.stopPropagation();

        this.props.navigate(RoutePaths.Landing);
    }


    private renderProfileSelection() {

        return this.state.showProfileSelection
            ? <ProfileSelection
                isDistrictAdministrator={this.props.isDistrictAdministrator}
                isPermissionClick={this.props.isPermissionClick}
                onCancel={() => this.setState({ showProfileSelection: false })}
            />
            : null;
    }

    private toggleProfileSelection() {

        this.setState({
            showProfileSelection: !this.state.showProfileSelection,
        });
    }
}
export const UserInfo = withRouter(connect(
    (_state) => ({
    }),
    dispatchers,
    undefined,
    { context: siteHeaderContext }
)(UserInfoComponent));
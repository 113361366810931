import { StyledButton, StyledOutlinedButton } from "@src/components/NewStyles";
import { highLight } from "@src/utils/SearchResult";
import { cloneDeep } from "lodash";
import * as React from "react";

export interface FilterItem {
    id: string;
    label: string;
}

interface Props {

    items?: FilterItem[];
    label: string;
    cssClasses?: string[];
    allowSearch: boolean;
    openOnIconOnly?: boolean;
    initialFilters?: string[];

    onOpened?(): void;
    onDone(selectedFilters: string[]): void;
    onCleared(): void;
}

interface State {

    searchQuery: string;
    showFilters: boolean;
    selectedFilters: string[];
    filtersChanged: boolean;
}

/** @deprecated This filter component is now replaced by FilterTreeView */
export class FilterHeader extends React.Component<Props, State> {

    public constructor(props: Props) {

        super(props);

        this.state = {

            searchQuery: null,
            showFilters: false,
            selectedFilters: props.initialFilters || [],
            filtersChanged: false,
        };
    }

    public render() {

        const items = this.props.items
            ? this.state.searchQuery
                ? this.props.items.filter((x) => x.label.toLowerCase().includes(this.state.searchQuery.toLowerCase()))
                : this.props.items
            : [];

        let classes = ["filters"];

        if (this.props.cssClasses
            && this.props.cssClasses.length > 0) {

            classes = classes.concat(this.props.cssClasses);
        }

        return <span className="header-filters">

            {
                this.state.showFilters
                    ? <div
                        className="filter-list-background"
                        onClick={(event) => this.closeFilters(event)}
                    ></div>
                    : null
            }

            <span onClick={!this.props.openOnIconOnly ? (event) => this.toggleShowFilters(event) : null}>
                <i
                    className={this.state.selectedFilters.length > 0 ? "fas fa-filter selected" : "far fa-filter"}
                    onClick={this.props.openOnIconOnly ? (event) => this.toggleShowFilters(event) : null}
                >
                </i>
                <span>{this.props.label}</span>
            </span>

            {
                this.state.showFilters
                    ? <div className={classes.join(" ")}>

                        {
                            this.props.allowSearch
                                ? <span className="search-container">
                                    <input
                                        type="text"
                                        className="search"
                                        placeholder="Search"
                                        aria-label="Search"
                                        autoFocus={true}
                                        value={this.state.searchQuery ? this.state.searchQuery : ""}
                                        onChange={(event) => this.searchChanged(event.target.value)}
                                    />

                                    <span className="clear-search"
                                        onClick={(event) => this.searchCleared(event)}
                                    >
                                        <i className="far fa-times"></i>
                                    </span>
                                </span>
                                : null
                        }

                        <div className="list">
                            {
                                items.map((item: FilterItem) => {

                                    const isSelected = this.state.selectedFilters.filter((x) => x === item.id).length > 0;

                                    return <p
                                        key={item.id}
                                        className="filter"
                                        onClick={(event) => this.selectFilterClicked(event, item)}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={isSelected}
                                        />
                                        {highLight(item.label, this.state.searchQuery)}
                                    </p>;
                                })
                            }
                        </div>
                        <footer className="alternate">
                            <StyledOutlinedButton onClick={(event) => this.clearFilters(event)}>Clear</StyledOutlinedButton>
                            <StyledButton onClick={(event) => this.closeFilters(event)}>Done</StyledButton>
                        </footer>
                    </div>
                    : null
            }
        </span>;
    }

    private toggleShowFilters(event: React.MouseEvent<HTMLElement, MouseEvent>) {

        event.stopPropagation();

        if (!this.state.showFilters
            && this.props.onOpened) {

            this.props.onOpened();
        }

        this.setState({
            showFilters: !this.state.showFilters,
            searchQuery: null,
        });
    }

    private selectFilterClicked(
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        item: FilterItem
    ) {

        event.stopPropagation();

        const items = cloneDeep(this.state.selectedFilters);

        if (this.state.selectedFilters.filter((x) => x === item.id).length > 0) {

            this.setState({
                selectedFilters: items.filter((x) => x !== item.id),
                filtersChanged: true,
            });
        }
        else {
            items.push(item.id);

            this.setState({
                selectedFilters: items,
                filtersChanged: true,
            });
        }
    }

    private clearFilters(event: React.MouseEvent<HTMLElement, MouseEvent>) {

        event.stopPropagation();

        this.setState({
            selectedFilters: [],
            filtersChanged: true,
        });
    }

    private searchChanged(value: string) {

        this.setState({ searchQuery: value });
    }

    private searchCleared(event: React.MouseEvent<HTMLElement, MouseEvent>) {

        event.stopPropagation();

        this.setState({ searchQuery: null });
    }

    private closeFilters(event: React.MouseEvent<HTMLElement, MouseEvent>) {

        event.stopPropagation();

        if (this.state.filtersChanged) {

            this.props.onDone(this.state.selectedFilters);
        }

        this.setState({
            showFilters: false,
            searchQuery: null,
            filtersChanged: false,
        });
    }
}

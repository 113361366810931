import * as React from "react";
import { ProfileListItem } from "../Domain/ProfileListItem";
import { Profile } from "./Profile";


interface Props {

    profiles: ProfileListItem[];
}

export class ProfileSelectionModal extends React.Component<Props, null> {

    public render() {

        return <div className="profile-selection-modal">
            <div
                className="modal-background">
            </div>

            <div
                className="modal-content-container"
                role="dialog" aria-labelledby="org_dialog_title"
                aria-modal="true"
            >
                <header>
                    <span id="org_dialog_title">Select Profile</span>
                </header>

                <p>Please select an alternate profile from the list below:</p>
                {
                    this.props.profiles.map((profile) => {

                        return <Profile
                            key={profile.id || profile.uri}
                            profile={profile}
                        />;
                    })
                }
            </div>
        </div>;
    }
}

import * as React from "react";
import { HourGlass } from "./HourGlass";

interface Props {
    isVisible: boolean;
    text?: string;
}

export class LoadingAnimation extends React.Component<Props> {

    public render() {

        const wrapperClasses = [];

        wrapperClasses.push("hourglass-wrapper");

        if (this.props.isVisible) {
            wrapperClasses.push("show");
        }

        return <div className={wrapperClasses.join(" ")}>
            {this.props.text}
            {
                this.props.isVisible
                    ? <HourGlass />
                    : null
            }

        </div>;
    }
}

import { cloneDeep } from "lodash";
import { SetOrganizationsAction, SetOrganizationsActionType } from "../Action/GetOrganizations";
import { SiteHeaderState } from "../Store";

export default {

    [SetOrganizationsActionType]: (state: SiteHeaderState, action: SetOrganizationsAction) => {

        const newState: SiteHeaderState = cloneDeep(state);

        newState.organizations = action.organizations;

        return newState;

    },
};

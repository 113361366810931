import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavbarLink } from './types';
import { checkCurrentUrl } from './utils';

const SubMenu = ({ links, label, onClick }: {
    links: NavbarLink[];
    label: string;
    onClick?(): void;
}) => {
    const location = useLocation();
    const currentUri = location.pathname;
    const currentHash = location.hash;
    const isActiveLink = checkCurrentUrl({ currentUri, currentHash });
    const hasActiveSubLink = links.some(({ url }) => isActiveLink(url));
    const [show, setShow] = useState(false);

    return (
        <li className="menu">
            <div className={`submenu ${hasActiveSubLink ? 'active' : ''}`} onClick={() => setShow(!show)}>
                <span className="nav-link">
                    {label}
                    <i className="far fa-angle-down" />
                </span>
            </div>

            <div className={!show ? "hidden" : ""}>
                <div className="admin-menu-background menu-background" onClick={() => setShow(!show)} />
                <ul className="nav-admin">
                    {
                        links.filter(({ enabled }) => enabled).map(link => (
                            <li className={`nochild`} key={link.label}>
                                {
                                    link.component
                                        ? link.component
                                        :
                                        <Link onClick={() => { onClick && onClick(); setShow(false); }} className={isActiveLink(link.url) ? 'active' : ''} to={link.url}>{link.label}</Link>
                                }
                            </li>
                        ))
                    }
                </ul>
            </div>
        </li>
    );
}

export { SubMenu };

import { BackgroundJobNotification } from "../BackgroundJobNotification";
import { SiteHeaderState } from "../Store";

export function getNotifications() {

    return async (_dispatch: never, getState: () => SiteHeaderState) => {

        const state = getState();

        return fetch(state.uris.getNotifications, {
            method: "GET",
            credentials: "same-origin",
        }).then(async (r) => (await r.json()) as BackgroundJobNotification[]);
    };
}



import jwt from "@src/utils/jwt";
import { WithRouterProps, withRouter } from "@src/utils/withRouter";
import * as React from "react";
import { ProfileListItem } from "../Domain/ProfileListItem";


interface Props extends WithRouterProps {

    profile: ProfileListItem;
}

class ProfileComponent extends React.Component<Props, null> {

    public render() {

        const classNames = ["profile"];

        if (this.props.profile.isSelected) {

            classNames.push("selected");
        }

        return <a
            className={classNames.join(" ")}
            onClick={() => this.selectProfile()}
        >
            {

                this.props.profile.logoUri
                    ? <img
                        src={this.props.profile.logoUri}
                        alt={this.props.profile.organizationName}
                        title={this.props.profile.organizationName}
                    />
                    : <span className="logo-placeholder">
                        {
                            this.props.profile.organizationName
                                ? <span>{this.props.profile.organizationName.substring(0, 1)}</span>
                                : ""
                        }
                    </span>
            }

            <span className="middle">
                <span title={this.props.profile.organizationName}>{this.props.profile.organizationName}</span>
                {
                    this.props.profile.districtName
                        ? <span title={this.props.profile.districtName}>{this.props.profile.districtName}</span>
                        : null
                }
                <span title={this.props.profile.userRole}>{this.props.profile.userRole}</span>
            </span>

            {
                this.props.profile.isSelected
                    ? <i className="fas fa-circle"></i>
                    : null
            }
        </a>;
    }
    async selectProfile() {
        await jwt.getToken(true, this.props.profile.id);

        if (this.props.profile.uri.startsWith("https://"))
            window.location.href = this.props.profile.uri;
        else
            this.props.navigate(this.props.profile.uri);
    }
}

export const Profile = withRouter(ProfileComponent);
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { getAppInsightsReactPlugin } from "@src/TelemetryProvider";
import { useSiteHeaderSelector } from "@src/customHooks/siteHeaderStore";
import "@src/themes/scss/apppage.scss";
import React, { FC, PropsWithChildren, useEffect } from "react";
import { ErrorComponent } from "../molecules/Error/Error";
import { PrivateFooter } from "../molecules/PrivateFooter/PrivateFooter";
import { SiteHeader } from "../molecules/SiteHeader/Component/SiteHeader";


interface Props extends PropsWithChildren {
    id?: string;
    pageTitle?: string;
}


export const AppPage: FC<Props> = ({ children, id, pageTitle }) => {
    const title = pageTitle ? pageTitle + " : Permission Click" : undefined;
    useEffect(() => {
        if (title)
            document.title = title;
    }, [title]);
    const error = useSiteHeaderSelector((s) => s.error);

    if (error) {
        return (
            <>
                <div id="site-header">
                    <header></header>
                </div>
                <div id={id || "content"}>
                    <ErrorComponent error={error} />
                </div>
                <div id="private-footer">
                    <PrivateFooter />
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <div id="site-header">
                    <SiteHeader />
                </div>
                <div id={id || "content"}>
                    <AppInsightsErrorBoundary onError={() => <ErrorComponent />} appInsights={getAppInsightsReactPlugin()}>
                        <>{children}</>
                    </AppInsightsErrorBoundary>
                </div>
                <div id="private-footer">
                    <PrivateFooter />
                </div>
            </>
        );
    }
}
